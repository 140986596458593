<!--
 * @Author: your name
 * @Date: 2021-03-04 14:53:29
 * @LastEditTime: 2021-03-04 14:59:22
 * @LastEditors: Please set LastEditors
 * @Description: 统计分析
 * @FilePath: \sd-vue-admin\src\views\project\marketing\statistics\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
